export default function useNavigation() {
  // Roteamento
  const route = useRoute()
  const router = useRouter()

  // Métodos
  function go(link, isExternal = false) {
    if (route.path !== link && isExternal) {
      navigateTo(link, { external: isExternal, open: { target: '_blank' } })
      return
    }

    if (route.path !== link && !isExternal) {
      navigateTo(link)
      return
    }

    return
  }

  function goForward() {
    router.forward();
  }

  function goBackwards() {
    router.back()
  }

  return {
    go,
    goForward,
    goBackwards
  }
}